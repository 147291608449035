<template>
  <v-card dark flat>
    <v-row class="mt-5">
      <v-col cols="12" class="d-flex align-center justify-start">
        <v-btn x-large icon @click="saveProfileAndLeave"><v-icon color="white">mdi-chevron-left</v-icon></v-btn>
        <span class="h6 mb-0">Profil</span>
      </v-col>
    </v-row>

    <v-card-text>
      <v-container>
        <v-form @submit.prevent ref="accountForm" class="ma-auto" style="max-width: 800px" lazy-validation novalidate>
          <v-row v-if="user">
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="user.vorname" label="Vorname" required></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="user.nachname" label="Nachname" persistent-hint required></v-text-field>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <v-text-field type="number" v-model="user.groesse" label="Grösse (in cm)" persistent-hint required></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field type="date" v-model="user.geburtstag" label="Geburtstag" persistent-hint required></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                :loading="validatingEmail === true"
                @blur="validateEmail"
                validate-on-blur
                :rules="rules.email"
                :error-messages="emailAlreadyUsed ? 'Diese E-Mail Adresse ist bereits vergeben' : ''"
                v-model="user.email"
                label="Email"
                required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field validate-on-blur :rules="rules.telefon" v-model="user.telefon" label="Telefon" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="user.adresse_1" label="Strasse und Hausnummer" required></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-text-field required validate-on-blur :rules="rules.isNumber" v-model="user.plz" label="PLZ"></v-text-field>
            </v-col>
            <v-col cols="8" sm="8" md="8">
              <v-text-field v-model="user.stadt" label="Stadt" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="user.land" label="Land" required></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-container>
        <v-row v-if="user">
          <v-col cols="12" sm="6" md="6">
            <v-btn @click="deleteUserAccount" color="red" text>Account löschen</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex"
import api from "@/api"
export default {
  name: "UserEditProfile",
  data() {
    return {
      user: null,
      userBackup: null,
      validatingEmail: false,
      emailAlreadyUsed: false,
      rules: {
        telefon: [(v) => /^(\+?\d{1,4}\s?){0,4}\d{1,10}$/.test(v) || "Bitte geben Sie eine gültige Telefonnummer ein"],
        plz: [
          (v) => !!v || "Dieses Feld wird benötigt.",
          (v) => v.length < 4 || "Bitte geben sie eine gültige PLZ ein.",
          (v) => /^\d*\.?\d+$/.test(v) || "Bitte gib eine Zahl ein.",
        ],
        email: [
          (v) => !!v || "Die E-Mail wird benötigt",
          (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(v) || "Das ist keine Gültige E-Mail",
        ],
        password: [(v) => !!v || "Bitte gib dein Passwort ein.", (v) => (v && v.length >= 8) || "Das Passwort muss mindestens 8 Zeichen lang sein."],
        isString: [
          (v) => !!v || "Dieses Feld wird benötigt.",
          (v) => /^[a-zA-Z\u00C0-\u024F ]+$/.test(v) || "Dieses Feld sollte keine Zahlen beeinhalten",
        ],
        isNumber: [(v) => !!v || "Dieses Feld wird benötigt.", (v) => /^\d*\.?\d+$/.test(v) || "Bitte gib eine Zahl ein."],
        required: [(v) => !!v || "Dieses Feld wird benötigt."],
      },
    }
  },
  mounted() {
    if (this.navigationVisible) this.toggleNavigationBarVisibility()
  },
  created() {
    this.fetchUser()
  },
  methods: {
    ...mapActions("navigationStoreNotPersisted", ["toggleNavigationBarVisibility", "setNavigationBarText", "setNavigationbarVisibility"]),
    async validateEmail() {
      console.log("validating E-mail")

      if (this.$refs.accountForm.validate()) {
        if (this.user.email.toLowerCase() === this.userBackup.email.toLowerCase()) {
          this.validatingEmail = "validated"
          this.emailAlreadyUsed = false
          return
        }
        this.validatingEmail = true
        this.emailAlreadyUsed = await api.getEmailAlreadyUsed({ email: this.user.email })

        this.validatingEmail = this.emailAlreadyUsed ? false : "validated"
      }
    },
    async saveProfileAndLeave() {
      if (JSON.stringify(this.userBackup) === JSON.stringify(this.user)) {
        this.$router.push("/user")
        return
      }
      this.validateEmail()
      if (!this.emailAlreadyUsed && this.$refs.accountForm.validate()) {
        try {
          this.user.email = this.user.email.toLowerCase()
          this.userBackup.email = this.userBackup.email.toLowerCase()
          let newUserData = await api.editUserProfile({ newUser: this.user, oldUser: this.userBackup })

          localStorage.removeItem("user")
          let userNewLocal = {
            _id: this.userBackup._id,
            email: this.user.email,
            vorname: this.user.vorname,
            nachname: this.user.nachname,
            studio_id: this.user.studio_id,
          }

          localStorage.setItem("user", JSON.stringify(userNewLocal))
          this.$toast.success("Profil gespeichert")
          this.$router.push("/user")
        } catch (e) {
          this.$toast.error("Fehler beim Speichern")
        }
      } else {
        this.$toast.error("Bitte überprüfe die Eingaben")
      }
    },

    async fetchUser() {
      try {
        let user = await api.getUser()
        this.user = user.user
        this.userBackup = JSON.parse(JSON.stringify(user.user))
      } catch (e) {
        this.$toast.error("Fehler beim Laden")
      }
    },
    deleteUserAccount() {
      if (confirm("Wollen Sie Ihren Account wirklich löschen?")) {
        api
          .deleteOwnAccount()
          .then((data) => {
            localStorage.removeItem("token")
            localStorage.removeItem("user")

            location.reload()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
  },
  computed: {
    ...mapState("navigationStoreNotPersisted", ["navigationItems", "navigationVisible"]),
  },
}
</script>

<style lang="scss" scoped></style>
